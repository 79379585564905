import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  tasks: []
}

const getters = {
  allTasks: (state) => state.tasks
}

const actions = {
  async fetchTasks ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/task/all', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setTaskItems', response.data.tasks)
  },
  async addTask({ commit }, taskItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/task/add`, {
        name: taskItem.name,
        second: taskItem.second,
        minute: taskItem.minute,
        hour: taskItem.hour,
        day_of_month: taskItem.day_of_month,
        day_of_week: taskItem.day_of_week,
        month: taskItem.month,
        active: taskItem.active,
        command: taskItem.command,
        control: taskItem.control,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushTaskItem', response.data.taskItem)
  },
  async deleteTask ({ commit }, taskItem) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/task/delete`, {
      taskItemId: taskItem._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remTaskItem', taskItem)
  },
  async updateTask ({ commit }, taskItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/task/update`, {
      taskItem
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remTaskItem', taskItem)
    commit('pushTaskItem', response.data.taskItem)
  }
}

const mutations = {
  setTaskItems: (state, taskItems) => {
    if (taskItems !== undefined) {
      state.tasks = taskItems
    }
  },
  pushTaskItem: (state, taskItem) => (state.tasks.unshift(taskItem)),
  remTaskItem: (state, deltaskItem) => (state.tasks = state.tasks.filter(taskItem => taskItem._id !== deltaskItem._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
