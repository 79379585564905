import { render, staticRenderFns } from "./NexaControllers.vue?vue&type=template&id=7bbb172a&scoped=true"
import script from "./NexaControllers.vue?vue&type=script&lang=js"
export * from "./NexaControllers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbb172a",
  null
  
)

export default component.exports