<template>
  <v-card>
    <v-tabs
      background-color="grey darken-3"
      centered
      grow
      dark
    >
      <v-tabs-slider color="blue-grey lighten-1"></v-tabs-slider>

      <v-tab>
        Rooms
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
              <HaabRooms/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab>
        Sensors
      </v-tab>
      <v-tab-item flat>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
            <HaabSensors/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab>
        Remote Controls
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
            <HaabControls/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab>
        Nexa Controllers
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
            <NexaControllers/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import HaabRooms from '@/components/services/haab/HaabRooms'
import HaabSensors from '@/components/services/haab/HaabSensors'
import HaabControls from '@/components/services/haab/HaabControls'
import NexaControllers from '@/components/services/haab/NexaControllers'

export default {
  name: 'HaabSetup',
  components: {
    HaabRooms,
    HaabSensors,
    HaabControls,
    NexaControllers
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  mounted () {
  },
  props: {
    mode: String
  },
  created () {
  }
}
</script>

<style scoped>
</style>
