module.exports = {
  api: {
    url: 'https://homeapi.haab.space',
    port: 443
  },
  weather: {
    key: 'b0b24cb7f8321327a3ee5142678aff20',
    url: 'https://api.openweathermap.org/data/2.5/weather'
  }
}
