<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="controls"
      item-key="name"
      multi-sort
      class="elevation-2 ma-5"
    >
      <v-subheader inset>Folders</v-subheader>
      {{controllers}}
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Controllers</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.label" label="Control Name"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.group" label="Group"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.button" label="Button"></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedRoom"
                    :items="availableRooms"
                    menu-props="auto"
                    label="Room"
                    hide-details
                    single-line
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedController"
                    :items="availableControllers"
                    menu-props="auto"
                    label="Controller"
                    hide-details
                    single-line
                  ></v-select>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HaabControls',
  data () {
    return {
      room: { label: 'Room:' },
      controller: { label: 'Controller:' },
      control: {
        room: {},
        controller: {}
      },
      dialog: false,
      deleteDialog: false,
      errors: [],
      fields: [
        {
          value: 'label',
          text: 'Label',
          sortable: false
        },
        {
          value: 'group',
          text: 'Group',
          sortable: true
        },
        {
          value: 'button',
          text: 'Button',
          sortable: true
        },
        {
          value: 'room.label',
          text: 'Room',
          sortable: true
        },
        {
          value: 'controller.label',
          text: 'Controller',
          sortable: true
        },
        {
          value: 'action',
          text: 'Action',
          sortable: true,
          width: '10%'
        }
      ],
      editedIndex: -1,
      editedItem: {
        label: '',
        group: '',
        button: '',
        room: {},
        controller: {}
      },
      defaultItem: {
        label: '',
        group: '',
        button: '',
        room: {},
        controller: {}
      },
      selectedRoom: '',
      selectedController: ''
    }
  },
  methods: {
    ...mapActions(['fetchControls']),
    ...mapActions(['fetchRooms']),
    ...mapActions(['fetchControllers']),
    ...mapActions(['addControl']),
    ...mapActions(['deleteControl']),
    ...mapActions(['updateControl']),
    editItem (control) {
      this.editedIndex = this.controls.indexOf(control)
      this.editedItem = Object.assign({}, control)    
      this.selectedRoom = control.room.label 
      this.selectedController = control.controller.label 
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.selectedRoom = ''
        this.selectedController = ''
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        var _room = {}
        var _controller = {}
        this.rooms.forEach(element => {
          if (element.label === this.selectedRoom) {
            _room = Object.assign({}, element)
          }
        })
        this.controllers.forEach(element => {
          if (element.label === this.selectedController) {
            _controller = Object.assign({}, element)
          }
        })
        this.editedItem.room = Object.assign({}, _room)
        this.editedItem.controller = Object.assign({}, _controller)
        Object.assign(this.controls[this.editedIndex], this.editedItem)
        this.updateControl(this.editedItem)
      } else {
        var _roomNew = {}
        var _controllerNew = {}
        this.rooms.forEach(element => {
          if (element.label === this.selectedRoom) {
            _roomNew = Object.assign({}, element)
          }
        })
        this.controllers.forEach(element => {
          if (element.label === this.selectedController) {
            _controllerNew = Object.assign({}, element)
          }
        })
        this.editedItem.room = Object.assign({}, _roomNew)
        this.editedItem.controller = Object.assign({}, _controllerNew)
        // console.log(this.editedItem)
        this.addControl(this.editedItem)
      }
      this.fetchControls()
      this.close()
    },
    deleteConfirm (control) {
      this.editedItem = Object.assign({}, control)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteControl(this.editedItem)
      this.close()
    }
  },
  props: {
  },
  mounted () {
    this.fetchControls()
    this.fetchControllers()
    this.fetchRooms()
  },
  computed: {
    ...mapGetters({
      controls: 'allControls',
      rooms: 'allRooms',
      controllers: 'allControllers'
    }),
    availableRooms () {
      var _rooms = []
      this.rooms.forEach(element => {
        _rooms.push(element.label)
      })
      return _rooms
    },
    availableControllers () {
      var _controllers = []
      this.controllers.forEach(element => {
        _controllers.push(element.label)
      })
      return _controllers
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Control' : 'Edit Control'
    }
  }
}
</script>

<style scoped>


</style>
