import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/core/Login'
import About from '@/components/core/About'
import Automation from '@/views/Automation'
import Dashboard from '@/views/Dashboard'
import Register from '@/components/core/Register'
import Setup from '@/components/services/haab/HaabSetup'
import HaabTasks from '@/components/services/haab/HaabTasks'
import Tasks from '@/components/services/lists/Tasks'
import Shopping from '@/components/services/lists/Shopping'
import Notes from '@/components/services/notes/Notes'
import Profile from '@/components/user/Profile'
import Admin from '@/components/user/Admin'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      guest: true
    }
  },
  {
    path: '/user/register',
    name: 'Register',
    props: true,
    component: Register,
    meta: {
      guest: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    props: true,
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/automation',
    name: 'Automation',
    component: Automation,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: Shopping,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/haab/setup',
    name: 'Setup',
    props: true,
    component: Setup,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/haab/tasks',
    name: 'Haab Tasks',
    props: true,
    component: HaabTasks,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/admin',
    name: 'Admin',
    props: true,
    component: Admin,
    meta: {
      // requiresAuth: true,
      isAdmin: true
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwtToken') === null) {
      next({
        path: '/login',
        params: {nextUrl: to.fullPath}
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwtToken') === null) {
      next()
    } else {
      next({name: 'Dashboard'})
    }
  } else if (to.matched.some(record => record.meta.isAdmin)) {
    if (store.getters.myUser.admin === true) {
      next()
    } else {
      next({name: 'Login'})
    }
  } else {
    next()
  }
})

export default router
