<template>
  <v-container fluid>
    <v-row>
      <v-col cols=12>
        <strong>Made with <a href="https://vuejs.org/">Vue</a> and <a href="https://vuetifyjs.com/">Vuetify</a></strong>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
