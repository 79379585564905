<template>
  <v-card
    class="elevation-3 ma-3"
    min-width="300"
    outlined
    v-if="!dead"
  >
    <v-system-bar
      :color=colour
      lights-out
      light
    >
      <span>{{ sensor.type }}</span>
      <v-spacer></v-spacer>
      <v-icon @click="updateSensor">mdi-reload</v-icon>
      <v-icon>{{batIcon}}</v-icon>
      <div class="caption">{{ batteryLevel }}</div>
      
    </v-system-bar>
    <div @click="dialog = true">
      <v-sheet
        class="ma-3"
        color="blue-grey lighten-1"
        elevation="3"
        max-width="calc(100% - 32px)"
      >
        <v-sparkline
          :value="data"
          color="white"
          line-width="2"
          padding="5"
        ></v-sparkline>
      </v-sheet>
      <v-card-text class="pt-0">
        <div class="title font-weight-light mb-2">Last value: {{last.value / last.scale}}</div>
        <div class="subheading font-weight-light grey--text">Haab Sensor ({{sensor.sensorid}})</div>
        <div class="subheading font-weight-light grey--text">{{sensor.name}}</div>
        <div class="subheading font-weight-light grey--text">{{sensor.unit}} units</div>
        <div class="subheading font-weight-light grey--text">{{sensor.building}}</div>
        <v-divider class="my-2"></v-divider>
        <v-icon
          class="mr-2"
          small
        >
          mdi-clock
        </v-icon>
        <span class="caption grey--text font-weight-light">Last measured: {{ lastMeasured }}</span>
      </v-card-text>
    </div>
    <v-dialog
      v-model="dialog"
      width="1100"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-1"
          primary-title
          >
          {{ sensor.type }} ({{sensor.sensorid}})
        </v-card-title>

        <v-card-text>
          <br>
          <apexchart v-if="itemsLoaded" height="450" type="line" :options="options" :series="series"></apexchart>
          <v-progress-circular
            v-if="loading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start"
                    label="From:"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="start" no-title scrollable @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="end"
                    label="To"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="end" no-title scrollable @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="updatePeriod"
            >
              Update
            </v-btn>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import config from '../../../config/index.js'
import axios from 'axios'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import ApexCharts from 'apexcharts'

export default {
  name: 'SensorBox',
  data () {
    return {
      battery_icon: {
        full: 'mdi-battery',
        ten: 'mdi-battery-10',
        twenty: 'mdi-battery-20',
        thirdy: 'mdi-battery-30',
        forty: 'mdi-battery-40',
        fifty: 'mdi-battery-50',
        sixty: 'mdi-battery-60',
        seventy: 'mdi-battery-70',
        eighty: 'mdi-battery-80',
        ninety: 'mdi-battery-90',
        charging: 'mdi-battery-charging'
      },
      current_battery: 0,
      errors: [],
      timezone: 2,
      dead: false,
      colour: "amber accent-3",
      temp: 'apexSensorChart' + this.sensor.index.toString(),
      dialog: false,
      start: new Date().toISOString().substr(0, 10),
      end: new Date().toISOString().substr(0, 10),
      periodStart: new Date(),
      periodEnd: new Date(),
      menu: false,
      modal: false,
      menu2: false,
      data: [],
      series: [{
        data: []
      }],
      battery_range: {
        low: 2300,
        full: 2900 
      },
      last: {},
      loading: true,
      loaded: false,
      options: {
        chart: {
          id: 'apexSensorChart',
          background: '#4e4e4e',
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: '#469e84',
            opacity: 0.85
          },
          width: '100%',
          height: 500,
          animations: {
            enabled: false,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            style: {
              colors: '#FFFFFF'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#FFFFFF'
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#67fad0'],
        stroke: {
          width: 2
        },
        theme: {
          mode: 'dark'
        },
        grid: {
          show: true,
          borderColor: '#469e84',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
          row: {
            colors: ['#151515', 'transparent'],
            opacity: 0.5
          },
          column: {
            colors: undefined,
            opacity: 0.5
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        markers: {
          size: 0,
          colors: undefined,
          strokeColors: '#469e84',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 0,
          discrete: [],
          shape: 'circle',
          radius: 3,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: 'dark',
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          onDatasetHover: {
            highlightDataSeries: false
          },
          x: {
            show: true,
            format: 'HH:mm dd/MMM',
            formatter: undefined
          },
          y: {
            formatter: undefined
          },
          z: {
            formatter: undefined,
            title: 'Size: '
          },
          marker: {
            show: true
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    }
  },
  components: {
  },
  methods: {
    isValid() {
      var last = moment(this.last.create_date).format('MM/DD/YYYY')
      var today = moment(today)
      var diff = today.diff(last, 'days')
      //console.log(diff)
      if (diff > 5)
        return false
      else
        return true
    },
    isDead() {
      var last = moment(this.last.create_date).format('MM/DD/YYYY')
      var today = moment(today)
      var diff = today.diff(last, 'days')
      //console.log(diff)
      if (diff > 20)
        return false
      else
        return true
    },
    updateSensor() {
      this.getLast()
    },
    updatePeriod() {
      this.periodStart = new Date(this.start)
      this.periodEnd = new Date(this.end)
      this.getAllbySensor()
    },
    map_range(value, low1, high1, low2, high2) {
      return low2 + (high2 - low2) * (value - low1) / (high1 - low1)
    },
    getAllbySensor () {
      this.options.xaxis.categories = []
      this.data = []
      axios.get(config.api.url + ':' + config.api.port + `/haab/measurements/bySensor/byDate/`, {
        params: {
          sensorid: this.sensor.sensorid,
          type: this.sensor.type,
          date_start: this.periodStart,
          date_end: this.periodEnd
        },
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
        .then(response => {
          response.data.measurements.forEach(element => {
            var newdate = new Date(element.measurement.create_date)
            newdate = newdate.valueOf() - newdate.getTimezoneOffset()*60*1000
            this.options.xaxis.categories.push(parseInt(newdate))
            this.data.push(element.measurement.value / element.measurement.scale)
          })

          if (this.data.length > 0) {
            this.loaded = true
            this.loading = false
          }

          this.series = [{
            name: this.sensor.type,
            data: this.data
          }]

          ApexCharts.exec('apexSensorChart', "updateOptions")

        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    getBatteryLevel () {
      axios.get(config.api.url + ':' + config.api.port + `/haab/measurements/bySensor/getLast/`, {
        params: {
          sensorid: this.sensor.sensorid,
          type: 'Battery'
        },
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
        .then(response => {
          if (response.data.measurement) {
            this.current_battery = parseFloat(response.data.measurement.value) / parseFloat(response.data.measurement.scale)
          }
          
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    getLast () {
      axios.get(config.api.url + ':' + config.api.port + `/haab/measurements/bySensor/getLast/`, {
        params: {
          sensorid: this.sensor.sensorid,
          type: this.sensor.type
        },
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      })
        .then(response => {
          this.last = Object.assign({}, response.data.measurement)
          this.periodEnd = new Date(response.data.measurement.create_date).valueOf()
          var start = this.periodEnd - 106400000
          this.periodStart = new Date(start)
          if (!this.sensorsLoaded) {
            this.getAllbySensor()
            this.getBatteryLevel()
          }
          if (!this.isValid()){
            this.colour = "purple accent-3"
          }
          if (!this.isDead()){
            this.dead = true
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    ...mapActions(['setSensorsLoaded']),
  },
  props: {
    sensor: Object
  },
  computed: {
    lastMeasured () {
      // new Date(this.last.create_date).toLocaleString()
      var temp = moment(this.last.create_date).format('DD/MM/YYYY, h:mm:ss a');
      return moment(temp, 'DD/MM/YYYY, h:mm:ss a').fromNow()
    },
    itemsLoaded () {
      if (this.data.length > 0){
        return true
      } else {
        return false
      }
    },
    ...mapGetters({
      sensorsLoaded: 'sensorsLoaded',
    }),
    batteryLevel () {
      if (this.current_battery > this.battery_range.full) {
        return 100 + '%'
      }
      else if (this.current_battery < this.battery_range.low) {
        return 0 + '%'
      }
      else {
        return Math.floor(this.map_range(this.current_battery, this.battery_range.low, this.battery_range.full, 0, 100)) + '%'
      }
    },
    batIcon () {
      let level = Math.floor(this.map_range(this.current_battery, this.battery_range.low, this.battery_range.full, 0, 100))
      if ((level > 0) && (level <=10)) {
        return this.battery_icon.ten
      }
      else if ((level > 10) && (level <=20)) {
        return this.battery_icon.twenty
      }
      else if ((level > 20) && (level <=30)) {
        return this.battery_icon.thirdy
      }
      else if ((level > 30) && (level <=40)) {
        return this.battery_icon.forty
      }
      else if ((level > 40) && (level <=50)) {
        return this.battery_icon.fifty
      }
      else if ((level > 50) && (level <=60)) {
        return this.battery_icon.sixty
      }
      else if ((level > 60) && (level <=70)) {
        return this.battery_icon.seventy
      }
      else if ((level > 70) && (level <=80)) {
        return this.battery_icon.eighty
      }
      else if ((level > 80) && (level <=90)) {
        return this.battery_icon.ninety
      }
      else if ((level > 90) && (level <=100)) {
        return this.battery_icon.full
      }
      return this.battery_icon.charging
    }
  },
  mounted () {
    if (!this.isValid()){
      this.colour = "purple accent-3"
    }
  },
  created () {
    this.token = localStorage.getItem('jwtToken')
    this.getLast()
    this.chartid = this.options.chart.id
  },
  watch: {
  }
}
</script>

<style scoped>


</style>
