import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
  icons: {
      iconfont: 'mdi'
    },
    theme: {
      dark: false,
      themes: {
        light: {
          // light: true,
          background: colors.grey.lighten5 // Not automatically applied
        },
        dark: {
          background: colors.grey.darken3// Not automatically applied
        }
      }
    }
});

Vue.use(Vuetify);