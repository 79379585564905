import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  todoItems: []
}

const getters = {
  allToDoItems: (state) => state.todoItems
}

const actions = {
  async fetchToDoItems ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/lists/todoItems', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setToDoItems', response.data.todoItems)
  },
  async addToDoItem ({ commit }, todoItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/lists/todoItem/add`, {
      description: todoItem.description
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushToDoItem', response.data.todoItem)
  },
  async deleteToDoItem ({ commit }, todoItem) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/lists/todoItem/delete`, {
      todoItemId: todoItem._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remToDoItem', todoItem)
  },
  async updateToDoItem ({ commit }, todoItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/lists/todoItem/update`, {
      todoItem: todoItem
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remToDoItem', todoItem)
    commit('pushToDoItem', response.data.todoItem)
  }
}

const mutations = {
  setToDoItems: (state, todoItems) => (state.todoItems = todoItems),
  pushToDoItem: (state, todoItem) => (state.todoItems.unshift(todoItem)),
  remToDoItem: (state, deltodoItem) => (state.todoItems = state.todoItems.filter(todoItem => todoItem._id !== deltodoItem._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
