<template>
  <v-container>
    <v-card
    class="mx-auto elevation-6"
    max-width="700"
    outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Server configuration</div>
        </v-list-item-content>

      </v-list-item>

      <v-card-text ref="textToCopy">
        <v-text-field
          v-model="config.serverName"
          name="input-10-1"
          label="Enter a server name"
          hint="haab"
        ></v-text-field>
        <v-text-field
          v-model="config.apiUrl"
          name="input-10-1"
          label="Enter the URL for the API"
          hint="If it is wrong, you will have to change directly in the DB later"
          readonly
        ></v-text-field>
        <v-text-field
          v-model="config.apiPort"
          name="input-10-1"
          label="Enter the port for the API"
          hint="If it is wrong, you will have to change directly in the DB later"
          readonly
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-divider></v-divider>
          <v-text-field
            v-model="config.weatherApi"
            name="input-10-1"
            label="Enter the URL for the Weather API"
            hint="Must be OpenWeather"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="config.weatherKey"
            name="input-10-2"
            label="Enter the API key for the Weather API"
            hint="Get one in the OpenWeatherMap website"
          ></v-text-field>
          <v-text-field
            v-model="config.weatherCity"
            name="input-10-2"
            label="Enter the city for the weather"
            hint="Any city"
          ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="error" @click="updateConfiguration(config)">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
    >
      {{ snackText }}

      <template v-slot:[`actions`]="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Config',
  components: {
  },
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
    }
  },
  methods: {
    ...mapActions(['fetchConfig']),
    ...mapActions(['updateConfig']),
    ...mapActions(['fetchWeather']),
    updateConfiguration (config) {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Saving'
      this.updateConfig(config)
      this.fetchWeather(config.weatherCity)
    }
  },
  computed: {
    ...mapGetters({
      config: 'config'
    })
  },
  props: {
  },
  mounted () {
  },
  created () {
    this.fetchConfig()
  },
}
</script>

<style scoped>

</style>
