<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <WeatherBox :weather="weather"></WeatherBox>
        </v-col>
        <v-col>
          <Nuages></Nuages>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import WeatherBox from '@/components/dashboard/weather/WeatherBox'
import Nuages from '@/components/dashboard/astronomy/Nuages'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    WeatherBox,
    Nuages
  },
  data () {
    return {
      errors: [],
      show: false
    }
  },
  methods: {
    ...mapActions(['fetchWeather']),
  },
  created () {
    this.fetchWeather(this.config.weatherCity)
  },
  props: {
    mode: String
  },
  computed: {
    ...mapGetters({
      weather: 'currentWeather',
      config: 'config'
    })
  }
}
</script>

<style>

</style>