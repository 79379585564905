import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  controls: []
}

const getters = {
  allControls: (state) => state.controls
}

const actions = {
  async fetchControls ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/nexa/controls', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setControls', response.data.controls)
  },
  async addControl ({ commit }, control) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/nexa/control/add`, {
      label: control.label,
      group: control.group,
      button: control.button,
      room: control.room._id,
      controller: control.controller._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushControl', response.data.control)
  },
  async deleteControl ({ commit }, control) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/nexa/control/delete`, {
      controlId: control._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remControl', control)
  },
  async updateControl ({ commit }, control) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/nexa/control/update`, {
      control: control
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remControl', control)
    commit('pushControl', response.data.control)
  }
}

const mutations = {
  setControls: (state, controls) => (state.controls = controls),
  pushControl: (state, control) => (state.controls.push(control)),
  remControl: (state, delcontrol) => (state.controls = state.controls.filter(control => control._id !== delcontrol._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
