import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
// import VueSocketIO from 'vue-socket.io'
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

Vue.component('apexchart', VueApexCharts)

/*const socketInstance = io('http://localhost:56101', {
  transports: ['websocket'],
});
Vue.use(new VueSocketIOExt({
  debug: true,
  connection: socketInstance
}))*/
//const socket = io('http://10.127.11.250:56101');
const socket = io('https://homeapi.haab.space');
Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
