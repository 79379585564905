import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  rooms: [],
  loaded: false
}

const getters = {
  allRooms: (state) => state.rooms
}

const actions = {
  async fetchRooms ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/haab/rooms', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    state.loaded = true
    commit('setRooms', response.data.rooms)
  },
  async addRoom ({ commit }, room) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/haab/room/add`, {
      label: room.label,
      info: room.info
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushRoom', response.data.room)
  },
  async deleteRoom ({ commit }, room) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/haab/room/delete`, {
      roomId: room._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remRoom', room)
  },
  async updateRoom ({ commit }, room) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/haab/room/update`, {
      room: room
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remRoom', room)
    commit('pushRoom', response.data.room)
  }
}

const mutations = {
  setRooms: (state, rooms) => (state.rooms = rooms),
  pushRoom: (state, room) => (state.rooms.push(room)),
  remRoom: (state, delroom) => (state.rooms = state.rooms.filter(room => room._id !== delroom._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
