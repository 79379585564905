<template>
  <v-card
    class="ma-3 elevation-6 blue-grey lighten-1"
    max-width="400"
    min-width="250"
  >

    <Data
      v-if="weather.main"
      :weather="weather"
    />
    <Figure
      v-if="weather.main"
      :weather="weather"
    />
    <Temperature
      v-if="weather.main"
      :weather="weather"
    />

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text>Full Report</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Temperature from '@/components/dashboard/weather/Temperature'
import Data from '@/components/dashboard/weather/Data'
import Figure from '@/components/dashboard/weather/Figure'

export default {
  name: 'WeatherBox',
  components: {
    Temperature,
    Data,
    Figure
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    weather: Object
  },
  created () {
  },
  computed: {
    toShow () {
      if (this.weather !== undefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
