<template>
<v-parallax class="fill-height" height="100%" src="@/assets/login.jpg">
  <v-container class="fill-height" >
    <v-row align="center" justify="center">
      <br>
      <div v-if="errors && errors.length">
        <div v-for="error of errors" :key="error.msg">
          <v-alert dismissible show>{{error.msg}}</v-alert>
        </div>
      </div>
      <v-col cols="12" sm="8" md="4">
        <v-toolbar
          color="secondary"
          dark
          prominent
          src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
        >
          <v-toolbar-title class="barcode text-center">Habitat Agriculture and Automated Biosphere</v-toolbar-title>
        </v-toolbar>
        <v-card class="elevation-6">
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="login.email"
                label="E-mail"
                name="login"
                :rules="emailRules"
                prepend-icon="mdi-account"
                type="text"
                required
              />
              <v-text-field
                v-model="login.password"
                id="password"
                label="Password"
                name="password"
                :rules="passRules"
                prepend-icon="mdi-lock"
                type="password"
                required
              />
              <v-card-actions>
                <v-spacer />
                <v-btn @click="onSubmit" color="primary">Login</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
      </v-parallax>
</template>

<script>

import axios from 'axios'
import config from '../../../config/index.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      login: {},
      errors: [],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passRules: [
        v => !!v || 'Password is required'
      ],
    }
  },
  methods: {
    ...mapActions(['fetchMyUser']),
    onSubmit () {
      if (this.$refs.form.validate()) {
      //  console.log(config.api.url)
      axios.post(config.api.url + ':' + config.api.port + `/users/login/`, this.login)
        .then(response => {
          this.afterAuthenticate(response)
        })
        .catch(err => {
          err.response.data.message.forEach(element => {
            this.errors.push(element)
          })
        })
      }
    },
    afterAuthenticate (response) {
      localStorage.setItem('jwtToken', response.data.token)
      localStorage.setItem('user', response.data.user)
      this.fetchMyUser()
      this.$emit('loginLogout', 'login')
      this.$router.push({
        name: 'Dashboard'
      })
    }
  },
  props: {
    mode: String
  },
  computed: {
    ...mapGetters({
      profile: 'myUser'
    }),
  }
}
</script>

<style scoped>


</style>
