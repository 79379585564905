<template>
  <v-footer
    dense
    :inset=true
    app
    dark
    class="font-weight-this; overline"
  > 
    <v-progress-circular
      v-if="isConnected"
      indeterminate
      color="amber"
      :size="20"
    ></v-progress-circular>
    <v-spacer></v-spacer>
    {{ weather.name }}, {{ weather.sys.country }}
    <span class="caption pl-2 pr-2">({{ weather.weather[0].description}})</span>
    {{temperature}}&deg;C 
    <span class="caption pl-2 blue--text">(&uarr;{{max}}°C &darr;{{min}}°C)</span>
    <v-img
      :src="url"
      alt="Icon"
      max-height="40"
      max-width="40"
      contain
    ></v-img>
    <v-icon>mdi-weather-windy</v-icon>
    {{ weather.wind.speed }} m/s
    <v-icon>mdi-water-percent</v-icon>
    {{ weather.main.humidity }}%
    <v-spacer></v-spacer>
    <span class="text-right">
      © BrunoCF {{ new Date().getFullYear() }}
    </span>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
  }),
  methods: {
    ...mapActions(['fetchWeather'])
  },
  props: {
    drawer: Boolean,
    isConnected: Boolean
  },
  created () {
    this.weather.sys = {}
    this.weather.main = {}
    this.weather.wind = {}
    this.weather.weather = [{}]
  },
  mounted () {
    this.fetchWeather(this.config.weatherCity)
  },
  computed: {
    ...mapGetters({
      weather: 'currentWeather',
      config: 'config'
    }),    
    url () {
      const pre = 'https://openweathermap.org/img/wn/'
      const pos = '@2x.png'
      var icon = pre + this.weather.weather[0].icon + pos
      return icon
    },
    temperature () {
      return Math.round(this.weather.main.temp - 273.15)
    },
    max () {
      return Math.round(this.weather.main.temp_max - 273.15)
    },
    min () {
      return Math.round(this.weather.main.temp_min - 273.15)
    }
  }
}
</script>

<style>

</style>
