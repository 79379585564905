<template>
  <v-navigation-drawer
    v-model="menu"
    app
    dark
    @transitionend="toggleMenu"
    src="@/assets/blur-bg.jpg"
  >
  <v-list dense>

    <v-list-item two-line @click="openProfile">
      <v-list-item-title class="title">
        <span >{{profile.name}}</span>
        <span class="font-weight-bold"></span>
      </v-list-item-title>
      
      <v-list-item-avatar>
        <v-img :src="myAvatar" lazy-src="https://picsum.photos/id/11/100/60" alt="Image of user's Avatar"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-divider></v-divider>
    <v-list-item v-if="_isAdmin" @click="openAdmin">
      <v-list-item-action>
          <v-icon>mdi-gavel</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Admin</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openDashboard">
      <v-list-item-action>
          <v-icon>mdi-monitor-dashboard</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openAutomation">
      <v-list-item-action>
          <v-icon>mdi-home</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Automation</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openHaabTasks">
      <v-list-item-action>
          <v-icon>mdi-home-automation</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Haab Tasks</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openShopping">
      <v-list-item-action>
          <v-icon>mdi-cart</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Shopping List</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openTasks">
      <v-list-item-action>
          <v-icon>mdi-calendar-check</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Tasks</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openNotes">
      <v-list-item-action>
          <v-icon>mdi-script-text-outline</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Notes</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="openSetup">
      <v-list-item-action>
          <v-icon>mdi-cog</v-icon>
      </v-list-item-action>
      <v-list-item-content>
          <v-list-item-title>Automation Setup</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-list>
  <template v-slot:append>
    <v-list dense>
      <v-list-item @click="openAbout">
        <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>About this page</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  <div class="pa-2">
    <v-btn block @click="logout">Logout</v-btn>
  </div>
  </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import config from '../../../config/index.js'

export default {
  data () {
    return {
      menu: false,
      isAdmin: false
    }
  },
  props: {
    drawer: Boolean
  },
  mounted () {
    this.menu = this.drawer
    this.fetchMyUser()
  },
  watch: {
    drawer () {
      this.menu = this.drawer
    }
  },
  methods: {
    ...mapActions(['fetchMyUser']),
    toggleMenu () {
      if (this.drawer !== this.menu) {
        this.$emit('toggleMenu')
      }
    },
    onImgError: function() {
    },
    openSetup () {
      this.$router.push({
        name: 'Setup'
      }).catch(()=>{});
    },
    openHaabTasks () {
      this.$router.push({
        name: 'Haab Tasks'
      }).catch(()=>{});
    },
    openAutomation () {
      this.$router.push({
        name: 'Automation'
      }).catch(()=>{});
    },
    openDashboard () {
      this.$router.push({
        name: 'Dashboard'
      }).catch(()=>{});
    },
    openTasks () {
      this.$router.push({
        name: 'Tasks'
      }).catch(()=>{});
    },
    openShopping() {
      this.$router.push({
        name: 'Shopping'
      }).catch(()=>{});
    },
    openNotes() {
      this.$router.push({
        name: 'Notes'
      }).catch(()=>{});
    },
    openAbout () {
      this.$router.push({
        name: 'About'
      }).catch(()=>{});
    },
    openAdmin () {
      this.$router.push({
        name: 'Admin'
      }).catch(()=>{});
    },
    openProfile () {
      this.$router.push({
        name: 'Profile'
      }).catch(()=>{});
    },
    logout () {
      localStorage.removeItem('jwtToken')
      localStorage.removeItem('user')
      this.$emit('loginLogout', 'logout')
      this.$store._actions.reset[0]
      this.$router.push({
        name: 'Login'
      })
    }
  },
  computed: {
    _isAdmin () {
      return this.profile.admin
    },
    ...mapGetters({
      profile: 'myUser'
    }),
    myAvatar () {
      if (this.profile.avatar !== null) {
        return config.api.url + ':' + config.api.port + `/`+this.profile.avatar
      }
      return config.api.url + ':' + config.api.port + `/default.jpg`
    }
  }
}
</script>

<style>

</style>
