<template>
  <div>
    <br>
    <div class="text-center">
        <h4 id="logo">HAAB</h4>
        <div :class="(mode === 'dark') ? 'barcode' : 'barcode-light'">Habitat Agriculture and Automated Biosphere</div>
    </div>
    <br>
    <b-container>
    <b-row class='justify-content-md-center'>
      <b-col :class="(mode === 'dark') ? 'col-sm-12-dark' : 'col-sm-12-light'" cols='6'>
        <h4 :class="(mode === 'dark') ? 'title-dark' : 'title-light'">Please Register</h4>
        <hr>
        <div v-if='errors && errors.length'>
          <div v-for='error of errors' :key='error.msg'>
            <b-alert dismissible  show>{{error.msg}}</b-alert>
          </div>
        </div>
        <b-form @submit='onSubmit'>
          <b-form-group
                    horizontal
                    :label-cols='4'
                    breakpoint='md'
                    label='Enter Username'>
            <b-form-input id='username' v-model.trim='register.name' required></b-form-input>
          </b-form-group>
          <b-form-group
                    horizontal
                    :label-cols='4'
                    breakpoint='md'
                    label='Enter E-mail'>
            <b-form-input id='email' v-model.trim='register.email' required></b-form-input>
          </b-form-group>
          <b-form-group
                    horizontal
                    :label-cols='4'
                    breakpoint='md'
                    label='Enter Password'>
            <b-form-input type='password' id='password' v-model.trim='register.password'></b-form-input>
          </b-form-group>
          <b-form-group
                    horizontal
                    :label-cols='4'
                    :validFeedback="validPass"
                    :invalid-feedback="invalidPass"
                    :state="state"
                    breakpoint='md'
                    label='Repeat Password'>
            <b-form-input type='password' id='password2' v-model.trim='register.password2' ></b-form-input>
          </b-form-group>
          <hr>
          <b-button type='submit' variant='primary'>Register</b-button>
          <b-button type='button' variant='success' @click='$router.go(-1)'>Cancel</b-button>
        </b-form>
        <br>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import config from '../../../config/index.js'

export default {
  name: 'Register',
  components: {
  },
  data () {
    return {
      register: {},
      repPassword: '',
      errors: [],
      gender: [
        { text: 'Select One', value: null },
        'Male',
        'Female',
        'Rather not share'
      ],
      countries: config.countries
    }
  },
  computed: {
    state () {
      if (this.register.length > 0) {
        return true
      } else {
        return false
      }
    },
    validPass () {
      if (this.register.password2 === this.register.password) {
        return ''
      } else {
        return 'Passwords must match'
      }
    },
    invalidPass () {
      if (this.register.password2 !== this.register.password) {
        return 'Passwords must match'
      } else {
        return ''
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      if (this.register.password2 === this.register.password) {
        axios.post(config.api.url + ':' + config.api.port + `/users/register`, this.register)
          .then(response => {
            this.trash = response
            alert('Registered successfully')
            this.$router.push({
              name: 'Login'
            })
          })
          .catch(err => {
            err.response.data.message.forEach(element => {
              this.errors.push(element)
            })
          })
      } else {
        alert('Review your form')
      }
    }
  },
  mounted () {
  },
  props: {
    mode: String
  }
}
</script>

<style scoped>

.form{
  min-width: 280px;
  margin: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
  background: linear-gradient(rgba(0, 0, 0, 0.71), rgba(92, 92, 92, 0.7));
  border: 1px solid rgba(28, 49, 49, 0.82);
  padding: 20px 10px 0px 10px;
  box-shadow: 5px 5px 4px #000000;
  margin: 0 auto
}

h3{
    color: #ffffff;
    padding-top: 4px;
}

</style>
