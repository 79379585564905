import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  sensors: [],
  loaded: false
}

const getters = {
  allSensors: (state) => state.sensors,
  sensorsLoaded: (state) => state.loaded
}

const actions = {
  async fetchSensors ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/haab/sensors', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    // state.loaded = true
    commit('setSensors', response.data.sensors)
  },
  async addSensor ({ commit }, sensor) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/haab/sensor/add`, {
      sensorid: sensor.sensorid,
      type: sensor.type,
      unit: sensor.unit,
      scale: sensor.scale,
      room: sensor.room,
      building: sensor.building,
      name: sensor.name
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushSensor', response.data.sensor)
  },
  async deleteSensor ({ commit }, sensor) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/haab/sensor/delete`, {
      sensorId: sensor._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remSensor', sensor)
  },
  async updateSensor ({ commit }, sensor) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/haab/sensor/update`, {
      sensor: sensor
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remSensor', sensor)
    commit('pushSensor', response.data.sensor)
  },
  async setSensorsLoaded () {
    state.loaded = true
  }

}

const mutations = {
  setSensors: (state, sensors) => (state.sensors = sensors),
  pushSensor: (state, sensor) => (state.sensors.push(sensor)),
  remSensor: (state, delsensor) => (state.sensors = state.sensors.filter(sensor => sensor._id !== delsensor._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
