import Vuex from 'vuex'
import Vue from 'vue'

import rooms from './modules/rooms'
import events from './modules/events'
import users from './modules/users'
import nexa from './modules/nexa'
import control from './modules/control'
import sensors from './modules/sensors'
import shopping from './modules/shopping'
import todo from './modules/todo'
import note from './modules/note'
import notecaterogy from './modules/notecategory'
import weather from './modules/weather'
import config from './modules/config'
import task from './modules/task'
import measurements from './modules/measurements'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    rooms,
    events,
    users,
    nexa,
    control,
    sensors,
    shopping,
    todo,
    note,
    notecaterogy,
    weather,
    config,
    task,
    measurements
  }
})
