<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="controllers"
      item-key="name"
      multi-sort
      class="elevation-2 ma-5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Controllers</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.label" label="Controller Name"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.ip" label="Controller IP"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.port" label="Controller Port"></v-text-field>
                </v-col>
                <v-col>
                  <v-switch v-model="editedItem.legacy" class="ma-4" label="Legacy"></v-switch>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NexaControllers',
  data () {
    return {
      controller: {},
      deleteDialog: false,
      dialog: false,
      errors: [],
      fields: [
        {
          value: 'label',
          text: 'Label',
          sortable: false
        },
        {
          value: 'ip',
          text: 'IP',
          sortable: true
        },
        {
          value: 'port',
          text: 'Port',
          sortable: true
        },
        {
          value: 'action',
          text: 'Action',
          sortable: false,
          width: '10%'
        }
      ],
      editedIndex: -1,
      editedItem: {
        label: '',
        ip: '',
        port: '',
        legacy: false
      },
      defaultItem: {
        label: '',
        ip: '',
        port: '',
        legacy: false
      }
    }
  },
  props: {
    mode: String
  },
  methods: {
    ...mapActions(['fetchControllers']),
    ...mapActions(['addController']),
    ...mapActions(['deleteController']),
    ...mapActions(['updateController']),
    editItem (controller) {
      this.editedIndex = this.controllers.indexOf(controller)
      this.editedItem = Object.assign({}, controller)     
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        // Object.assign(this.controllers[this.editedIndex], this.editedItem)
        this.updateController(this.editedItem)
      } else {
        this.addController(this.editedItem)
      }
      this.fetchControllers()
      this.close()
    },
    deleteConfirm (room) {
      this.editedItem = Object.assign({}, room)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteController(this.editedItem)
      this.close()
    },
    isLegacy (item) {
      return item ? true : false
    }
  },
  mounted () {
    this.fetchControllers()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      controllers: 'allControllers'
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Controller' : 'Edit Controller'
    }
  }
}
</script>

<style scoped>

</style>
