<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="users"
      :items-per-page="15"
      class="elevation-1"
      dense
      calculate-widths>
      <template v-slot:[`item.action`]="{ item }"> 
        <v-tooltip top>
          <template v-slot:[`activator`]="{ on, attrs }"> 
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteConfirm(item)"
            >
              mdi-delete-empty
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              @click="generateKeyConfirm(item)"
            >
              mdi-autorenew
            </v-icon>
          </template>
          <span>New Key</span>
        </v-tooltip>

      </template>
      <template v-slot:[`item.admin`]="{ item }">
        <v-switch
          dense
          inset
          color="warning"
          @change="update(item)"
          v-model="item.admin"
        ></v-switch>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.name"
          @save="save"
          @cancel="cancel"
          @close="close"
        > 
          <v-text-field
            v-model="item.name"
            single-line
            disabled
            dense
          ></v-text-field>  
          <template v-slot:input>
            <v-text-field
              @change="update(item)"
              v-model="item.name"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>Current Active Users</v-toolbar-title>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline"> New User </span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                </v-col>
                <v-col>
                  <v-switch
                    class="pt-6"
                    v-model="editedItem.admin"
                    label="Is admin"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="editedItem.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="input-10-1"
                    label="Enter a new password"
                    hint="At least 8 characters"
                    counter
                    @click:append="show = !show"
                  ></v-text-field>
                  <v-text-field
                    v-model="editedItem.passwordRepeat"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.match]"
                    :type="show ? 'text' : 'password'"
                    name="input-10-2"
                    label="Repeat the same password"
                    hint="Passwords must match"
                    value="wqfasds"
                    class="input-group--focused"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="generateDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Generate new Api Key?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="generateKey">Yes</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      fields: [
        {
          value: 'email',
          text: 'Login email',
          sortable: true,
          width: '20%'
        },
        {
          value: 'name',
          text: 'Username',
          sortable: true,
          width: '20%'
        },
        {
          value: 'admin',
          text: 'Is admin?',
          sortable: false,
          width: '10%'
        },
        {
          value: 'shakey',
          text: 'ApiKey',
          sortable: false,
          width: '40%'
        },
        {
          value: 'createDate',
          text: 'Created in',
          sortable: true
        },
        {
          value: 'action',
          text: '',
          sortable: false,
          width: '5%'
        }
      ],
      show: false,
      dialog: false,
      deleteDialog: false,
      generateDialog: false,
      snack: false,
      snackColor: '',
      snackText: '',
      editedIndex: -1,
      editedItem: {
        email: '',
        password: '',
        passwordRepeat: '',
        name: '',
        admin: false,
        generate: false
      },
      defaultItem: {
        email: '',
        password: '',
        passwordRepeat: '',
        name: '',
        admin: false,
        generate: false
      },
      rules: {
        size: value => !value || value.size < 1500000 || 'Avatar size should be less than 2 MB!',
        required: value => !!value || 'Required.',
        min: value => value.length >= 8 || 'Min 8 characters',
        match: value => value == this.editedItem.password || 'Passwords must match',
        emailMatch: () => ('The email and password you entered don\'t match'),
      }
    }
  },
  filters: {
  },
  methods: {
    ...mapActions(['fetchUsers']),
    ...mapActions(['fetchMyUser']),
    ...mapActions(['updateUser']),
    ...mapActions(['addUser']),
    ...mapActions(['deleteUser']),
    update (user) {
      this.editedIndex = this.users.indexOf(user)
      this.editedItem = Object.assign({}, user)
      this.editedItem['generate'] = false
      this.updateUser(this.editedItem)
      this.fetchMyUser()
      this.snackColor = 'success'
      this.snackText = 'Data saved'
      this.snack = true
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    deleteConfirm (user) {
      this.editedItem = Object.assign({}, user)
      this.deleteDialog = true
    },
    generateKeyConfirm (user) {
      this.editedIndex = this.users.indexOf(user)
      this.editedItem = Object.assign({}, user)
      this.generateDialog = true
    },
    deleteItem () {
      this.deleteUser(this.editedItem)
      this.close()
    },
    generateKey () {
      this.editedItem['generate'] = true
      this.updateUser(this.editedItem)
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
      this.close()
      this.snackColor = 'success'
      this.snackText = 'New key generated'
      this.snack = true
      this.fetchUsers()
      this.fetchMyUser()
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateUser(this.editedItem)
      } else {
        this.addUser(this.editedItem)
      }
      this.snackColor = 'success'
      this.snackText = 'Data saved'
      this.snack = true
      this.close()
      this.fetchMyUser()
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      this.generateDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
  },
  mounted () {
  },
  created () {
    this.fetchUsers()
  },
  computed: {
    ...mapGetters({
      users: 'allUsers'
    })
  }
}
</script>

<style scoped>

</style>
