<template>
  <v-container class="fill-height">
    <v-col cols=12 v-show="$vuetify.breakpoint.smAndUp">
      <v-row>
        <EventBox
          >
        </EventBox>
      </v-row>
    </v-col>
    <v-row justify="center" class="barcode mt-5">
      Habitat Agriculture and Automated Biosphere
    </v-row>

    <v-row>
      <v-col cols=12>
        <RoomBox
          v-for="room in rooms"
          v-bind:key="room.id"
          v-bind:room="room"
          v-bind:mode="mode"
        ></RoomBox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RoomBox from '@/components/automation/RoomBox'
import EventBox from '@/components/automation/EventBox'
import { mapGetters, mapActions } from 'vuex'
/* import draggable from 'vuedraggable' */

export default {
  name: 'Automation',
  components: {
    RoomBox,
    EventBox
  },
  data () {
    return {
      errors: [],
      show: false
    }
  },
  methods: {
    ...mapActions(['fetchRooms']),
    ...mapActions(['fetchWeather']),
  },
  created () {
    this.fetchRooms()
    this.fetchWeather(this.config.weatherCity)
  },
  props: {
    mode: String
  },
  computed: {
    ...mapGetters({
      rooms: 'allRooms',
      weather: 'currentWeather',
      config: 'config'
    })
  }
}
</script>

<style scoped>


</style>
