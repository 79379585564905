<template>
  <v-app-bar app dark dense>
    <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
    <v-toolbar-title class="grey--text">
      <span>haab</span>
      <span class="font-weight-bold">ify</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
      <v-switch
        class="pt-6"
        v-model="$vuetify.theme.dark"
        label="Dark"
      ></v-switch>
  </v-app-bar>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  components : {
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu')
    }
  },
  props: {
    drawer: Boolean
  },
  created () {
  },
  mounted () {
  },
  computed: {  
  }
}
</script>

<style>

</style>
