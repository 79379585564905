import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  categories: []
}

const getters = {
  allCategories: (state) => state.categories
}

const actions = {
  async fetchCategories ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/notes/categories', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    response.data.categories.forEach(element => {
      element.selected = false
    });
    commit('setCategories', response.data.categories)
  },
  async addCategory ({ commit }, category) {
    var color = ''

    if (typeof category.color === 'object') {
      color = category.color.hexa
    } else {
      color = category.color
    }

    const token = localStorage.getItem('jwtToken')

    const response = await axios.post(config.api.url + ':' + config.api.port + `/notes/category/add`, {
        label: category.label,
        color
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    // console.log(response.data)
    response.data.category.selected = false
    commit('pushCategory', response.data.category)
    return(["Success", response.data.category])
  },
  async deleteCategory ({ commit }, category) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/notes/category/delete`, {
      categoryId: category._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remCategory', category)
  },
  async updateCategory ({ commit }, category) {
    if (typeof category.color === 'object') {
      category.color = category.color.hexa
    }
    //category.color = "category.color.hexa"
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/notes/category/update`, {
      category: category
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remCategory', category)
    commit('pushCategory', response.data.category)
  }
}

const mutations = {
  setCategories: (state, categories) => (state.categories = categories),
  pushCategory: (state, category) => (state.categories.push(category)),
  remCategory: (state, delcategory) => (state.categories = state.categories.filter(category => category._id !== delcategory._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
