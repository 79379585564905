<template>
  <v-card
    class="elevation-3 ma-3 d-flex flex-column"
    max-width="200"
    outlined
  >
    <v-system-bar
      color="amber accent-3"
      lights-out
      light
    >
      <span>{{ control.label }}</span>
      <v-spacer></v-spacer>
      <v-icon>mdi-wifi-strength-4</v-icon>
    </v-system-bar>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">Haab Nexa</div>
        <!-- <v-list-item-title class=" mb-1">{{ control.label }}</v-list-item-title> -->
      </v-list-item-content>
    </v-list-item>


    <v-card-text class="pt-0">
      <div class="subheading font-weight-light grey--text">{{control.controller.label}}</div>
      <div class="subheading font-weight-light grey--text">{{control.controller.ip}}</div>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <v-btn color="success" @click="haabNexaSend('ON')">ON</v-btn>
      <v-btn color="secondary" @click="haabNexaSend('OFF')">OFF</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import config from '../../../config/index.js'

export default {
  name: 'ControlBox',
  data () {
    return {
    }
  },
  methods: {
    haabNexaSend: function (command) {
      // console.log(this.control)
      if (this.control.controller.legacy === true) {
        if (command === 'ON') {
          this.sendCommand = this.control.group.toString() + this.control.button.toString() + '1'
        } else if (command === 'OFF') {
          this.sendCommand = this.control.group.toString() + this.control.button.toString() + '0'
        }
        axios.post(config.api.url + ':' + config.api.port + `/nexa/legacy/`, {
          command: this.sendCommand
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
          .then(response => {
            this.trash = response
          })
          .catch(e => {
            this.trash = e
          })
      } else {
        axios.post(config.api.url + ':' + config.api.port + `/nexa/novel/`, {
          command: {
            Channel: this.control.group.toString(),
            Button: this.control.button.toString(),
            Command: command
          }
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
          .then(response => {
            this.trash = response
          })
          .catch(e => {
            this.trash = e
          })
      }
    }
  },
  props: {
    control: Object,
    mode: String
  },
  mounted () {
    this.token = localStorage.getItem('jwtToken')
  }
}
</script>

<style scoped>


</style>
