import axios from 'axios'
import config from '../../../config/index.js'
import moment from 'moment'

const state = {
  sensorsList: [],
  measurements: [],
  loaded: false
}

const getters = {
  allMeasurements: (state) => state.sensorsList,
  isLoaded: (state) => state.loaded
}

const actions = {
  async fetchLastMeasurement ({ commit }, sensor) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/haab/measurements/bySensor/getLast/', {
      params: {
        sensorid: sensor.sensorid,
        type: sensor.type
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    var last = response.data.measurement
    var periodEnd = moment(response.data.measurement.create_date).valueOf()
    var start =  periodEnd - 106400000
    var periodStart = new Date(start)
    commit('setSensorsList', { sensor, last, periodEnd, periodStart })
  },
  async fetchAllbySensor ({ commit }, _sensor) {
    const token = localStorage.getItem('jwtToken')
    const sensor_filter = state.sensorsList.filter(sensor => (sensor.sensorid === _sensor.sensorid && sensor.type === _sensor.type) )
    if (Object.entries(sensor_filter).length > 0) {
      const response = await axios.get(config.api.url + ':' + config.api.port + '/haab/measurements/bySensor/byDate/', {
        params: {
          sensorid: sensor_filter[0].sensorid,
          type: sensor_filter[0].type,
          date_start: sensor_filter[0].periodStart,
          date_end: sensor_filter[0].periodEnd
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      //const sensorIndex = state.sensorsList.findIndex((obj => obj.sensorid === _sensor.sensorid && obj.type === _sensor.type));
      //state.sensorsList[sensorIndex].measurements = response.data.measurements 
      //console.log(state.sensorsList[sensorIndex].measurements)
      const measurements = response.data.measurements
      //console.log(measurements)
      commit('updateSensorsList', {_sensor, measurements})
    }
  }
}

const mutations = {
  setSensorsList: (state, {sensor, last, periodEnd, periodStart}) => {
    if (state.sensorsList.length == 0) {
      state.sensorsList.push({
        sensorid: sensor.sensorid,
        type: sensor.type,
        last: last,
        periodStart: periodStart,
        periodEnd: periodEnd,
        loaded: false
      })
    } else {
      const found = state.sensorsList.filter(_sensor => (_sensor.sensorid === sensor.sensorid && _sensor.type === sensor.type))
      if (Object.entries(found).length > 0) {
        found.last = last
        found.periodStart = periodStart
        found.periodEnd = periodEnd
      } else {
          state.sensorsList.push({
            sensorid: sensor.sensorid,
            type: sensor.type,
            last: last,
            periodStart: periodStart,
            periodEnd: periodEnd,
            loaded: false
          })
      }
    }
    //actions.fetchAllbySensor(sensor)
  },
  updateSensorsList: (state, {_sensor, measurements}) => {
    const sensorIndex = state.sensorsList.findIndex((obj => obj.sensorid === _sensor.sensorid && obj.type === _sensor.type));
    state.sensorsList[sensorIndex].measurements = measurements
    state.sensorsList[sensorIndex].loaded = true
    state.loaded = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
