<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="rooms"
      item-key="name"
      multi-sort
      class="elevation-2 ma-5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Rooms</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.label" label="Room Name"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.info" label="Room Description"></v-text-field>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HaabRooms',
  data () {
    return {
      room: {},
      deleteDialog: false,
      dialog: false,
      errors: [],
      fields: [
        {
          value: 'label',
          text: 'Label',
          sortable: false
        },
        {
          value: 'info',
          text: 'Info',
          sortable: false
        },
        {
          value: 'action',
          text: 'Action',
          sortable: true,
          width: '10%'
        }
      ],
      editedIndex: -1,
      editedItem: {
        label: '',
        info: ''
      },
      defaultItem: {
        label: '',
        info: ''
      }
    }
  },
  filters: {
  },
  methods: {
    ...mapActions(['fetchRooms']),
    ...mapActions(['addRoom']),
    ...mapActions(['deleteRoom']),
    ...mapActions(['updateRoom']),
    editItem (room) {
      this.editedIndex = this.rooms.indexOf(room)
      this.editedItem = Object.assign({}, room)     
      this.dialog = true
    },
    deleteConfirm (room) {
      this.editedItem = Object.assign({}, room)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteRoom(this.editedItem)
      this.close()
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateRoom(this.editedItem)
      } else {
        this.addRoom(this.editedItem)
      }
      this.close()
    }
  },
  props: {
  },
  mounted () {
    this.fetchRooms()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      rooms: 'allRooms'
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Room' : 'Edit Room'
    },
  }
}
</script>

<style scoped>

</style>
