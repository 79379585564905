<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <Navigation v-if="login" v-on:toggleMenu="toggleMenu" v-bind:drawer="drawer" v-on:loginLogout="controlMenu($event)"></Navigation>
    <Appbar v-if="login" v-on:toggleMenu="toggleMenu" v-bind:drawer="drawer"></Appbar>
    <v-main>
      <router-view v-on:loginLogout="controlMenu($event)"/>
    </v-main>
    <Footer v-bind:isConnected="isConnected"></Footer>
  </v-app>
</template>

<script>
import Navigation from '@/components/core/Navigation'
import Footer from '@/components/core/Footer'
import Appbar from '@/components/core/Appbar'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
    Appbar
  },
  data: () => ({
    drawer: false,
    login: false,
    isConnected: false
  }),
  methods: {
    ...mapActions(['fetchConfig']),
    toggleMenu () {
      this.drawer = !this.drawer
    },
    controlMenu (event) {
      if (event === 'logout') {
        this.login = false
      } else if (event === 'login') {
        this.login = true
      }
    },
    isLogged () {
      if (localStorage.getItem('jwtToken') !== null) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    this.fetchConfig()
    .then((response) => {
      if (response[0] == "LOGOUT") {
        this.login = false
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('user')
        this.$store._actions.reset[0]
        this.$router.push({
          name: 'Login'
        })
      }
    })
  },
  mounted () {
    if (this.isLogged()) {
      this.login = true
    } else {
      this.login = false
    }
  },  
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  sockets: {
    connect () {
      this.isConnected = true
    },
    disconnect () {
      this.isConnected = false
    }
  },
}
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Audiowide);
@import url(https://fonts.googleapis.com/css?family=Orbitron);
@import url(https://fonts.googleapis.com/css?family=Libre+Barcode+128+Text);

#logo {
  font-family: Audiowide, Helvetica, Geneva, Arial, SunSans-Regular, sans-serif;
  color: white;
  text-align: center;
  text-shadow: 3px 2px rgb(38, 138, 18);
  font-size: 40px;
}

#mini-logo {
  font-family: Audiowide, Helvetica, Geneva, Arial, SunSans-Regular, sans-serif;
  color: white;
  text-align: center;
  text-shadow: 3px 2px rgb(38, 138, 18);
  font-size: 15px;
}

.barcode {
  font-family: "Libre Barcode 128 Text", Helvetica, Geneva, Arial, SunSans-Regular, sans-serif;
  text-align: center;
  font-size: 40px;
}

.back{
  background-attachment: fixed;
  background-image: url(./assets/blur-bg.jpg);
}

</style>
