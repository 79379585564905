<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="tasks"
      item-key="name"
      multi-sort
      class="elevation-2 ma-5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Tasks</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-col>
                  <v-text-field dense v-model="editedItem.name" label="Task Name"></v-text-field>
                </v-col>
                <v-row>
                <v-col>
                  <v-text-field
                  outlined
                  dense 
                  v-model="editedItem.second"
                  label="ss">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                  outlined
                  dense 
                  v-model="editedItem.minute"
                  label="mm">
                  </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    outlined
                    dense 
                    v-model="editedItem.hour"
                    label="hh">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    outlined
                    dense 
                    v-model="editedItem.day_of_month"
                    label="DD">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    outlined
                    dense 
                    v-model="editedItem.month"
                    label="MM">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    outlined
                    dense 
                    v-model="editedItem.day_of_week"
                    label="ww">
                    </v-text-field>
                </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-switch
                         dense
                         v-model="editedItem.active"
                         label="Active">
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                      <v-btn
                          color="purple"
                          v-on="on"
                      >
                      {{editedItem.control.label | label}}
                      </v-btn>

                      </template>
                    <v-list >
                    <v-subheader>Control</v-subheader>
                      <v-list-item
                          v-for="control in controls"
                          :key="control._id"
                          @click="selectControl(control)"
                      >
                          <v-list-item-title>{{ control.label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-combobox
                      v-model="editedItem.command"
                      :items="commands"
                      label="Command:"
                      outlined
                      dense
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HaabTasks',
  data () {
    return {
      task: {},
      deleteDialog: false,
      dialog: false,
      errors: [],
      commands: [
        'ON',
        'OFF'
      ],
      fields: [
        {
          value: 'name',
          text: 'Name',
          sortable: false
        },
        {
          value: 'active',
          text: 'Active',
          sortable: false
        },
        {
          value: 'control.label',
          text: 'Control',
          sortable: false
        },
        {
          value: 'command',
          text: 'Command',
          sortable: false
        },
        {
          value: 'second',
          text: 'Sec',
          sortable: false
        },
        {
          value: 'minute',
          text: 'Min',
          sortable: false
        },
        {
          value: 'hour',
          text: 'Hour',
          sortable: false
        },
        {
          value: 'day_of_month',
          text: 'Day',
          sortable: false
        },
        {
          value: 'month',
          text: 'Month',
          sortable: false
        },
        {
          value: 'day_of_week',
          text: 'Day of Week',
          sortable: false
        },
        {
          value: 'action',
          text: 'Action',
          sortable: true,
          width: '10%'
        }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        second: '',
        minute: '',
        hour: '',
        day_of_month: '',
        day_of_week: '',
        month: '',
        active: true,
        command: '',
        control: '',
      },
      defaultItem: {
        name: '',
        second: '',
        minute: '',
        hour: '',
        day_of_month: '',
        day_of_week: '',
        month: '',
        active: true,
        command: '',
        control: '',
      }
    }
  },
  methods: {
    ...mapActions(['fetchControls']),
    ...mapActions(['fetchTasks']),
    ...mapActions(['addTask']),
    ...mapActions(['deleteTask']),
    ...mapActions(['updateTask']),
    editItem (task) {
      this.editedIndex = this.tasks.indexOf(task)
      this.editedItem = Object.assign({}, task)     
      this.dialog = true
    },
    deleteConfirm (task) {
      this.editedItem = Object.assign({}, task)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteTask(this.editedItem)
      this.close()
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateTask(this.editedItem)
      } else {
        this.addTask(this.editedItem)
      }
      this.close()
    },
    selectControl(control) {
      this.editedItem.control = Object.assign({}, control)
    }
  },
  props: {
  },
  mounted () {
    this.fetchTasks()
    this.fetchControls()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      tasks: 'allTasks',
      controls: 'allControls',
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Task' : 'Edit Task'
    }
  },
  filters: {
    label (category) {
      return (typeof category === 'undefined') ? 'Select Control' : category
    },
    name (control) {
      return control.label
    }
  }
}
</script>

<style scoped>

</style>
