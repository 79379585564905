<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Last Events:
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="elevation-6" outlined>
          <v-col cols=12>
            <v-data-table
              :headers="fields"
              :items="events"
              :items-per-page="4"
              class="elevation-1"
              dense
              calculate-widths
            >
            <template v-slot:[`item.create_date`]="{ item }">
              {{ item.create_date |  localtime }}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EventBox',
  components: {
  },
   data () {
    return {
      fields: [
        {
          value: 'create_date',
          text: 'DB Timestamp',
          sortable: true,
          width: '20%'
        },
        {
          value: 'timestamp',
          text: 'Timestamp',
          sortable: true,
          width: '10%'
        },
        {
          value: 'text',
          text: 'Text',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchEvents'])
  },
  props: {
  },
  created () {
    this.fetchEvents()
  },
  computed: {
    ...mapGetters({
      events: 'allEvents'
    })
  },
  filters: {
    localtime: function (utc_time) {
      const utc_date = new Date(utc_time);
      var localstring = utc_date.toLocaleString()
      return localstring.replace(',','');
    }
  }
}
</script>

<style scoped>

</style>
