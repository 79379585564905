<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="headline">{{ weather.name }}, {{ weather.sys.country }}</v-list-item-title>
      <v-list-item-subtitle>{{ weather.weather[0].description}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'Data',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    weather: Object
  },
  mounted () {
  },
  computed: {
  }
}
</script>

<style scoped>

</style>>
