import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  controllers: []
}

const getters = {
  allControllers: (state) => state.controllers
}

const actions = {
  async fetchControllers ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/nexa/controllers', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setControllers', response.data.controllers)
  },
  async addController ({ commit }, controller) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/nexa/add`, {
      label: controller.label,
      ip: controller.ip,
      port: controller.port,
      legacy: controller.legacy
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushController', response.data.nexa)
  },
  async deleteController ({ commit }, controller) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/nexa/delete`, {
      controllerId: controller._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remController', controller)
  },
  async updateController ({ commit }, controller) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/nexa/update`, {
      controller: controller
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remController', controller)
    commit('pushController', response.data.controller)
  }
}

const mutations = {
  setControllers: (state, controllers) => (state.controllers = controllers),
  pushController: (state, controller) => (state.controllers.push(controller)),
  remController: (state, delcontroller) => (state.controllers = state.controllers.filter(controller => controller._id !== delcontroller._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
