<template>
  <div class=blue-grey>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-weather-windy</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.wind.speed }} m/s, {{ weather.wind.deg }}°</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-water-percent</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.main.humidity }}%</v-list-item-subtitle>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'Temperature',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    weather: Object
  },
  mounted () {
  },
  computed: {
  }

}
</script>

<style scoped>


</style>
