<template>
<v-container>
  <v-card
    class="ma-3 elevation-6 blue-grey lighten-1"
    max-width="600"
    min-width="250"
  >
    <v-card-title>Clouds coverage</v-card-title>
    <v-img class="ma-3" src="https://data.webastro.net/metastro/metastrogramme.php?&lat=59.601102&long=16.573135">
    </v-img>
  </v-card>
</v-container>

</template>

<script>
export default {

}
</script>

<style>

</style>