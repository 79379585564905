<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="timeFixedSensors"
      item-key="name"
      multi-sort
      :expanded.sync="expanded"
      class="elevation-2 ma-5"
      show-expand
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editSensor(item)"
        >
          mdi-square-edit-outline
        </v-icon>
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td class="teal darken-1">Time Stamp = {{ item.timeStamp }}</td>
        <td class="teal darken-1">Unit system = {{ item.unit }}</td>
        <td class="teal darken-1">Scale = {{ item.scale }}</td>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Active Sensors</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-subheader inset>New sensors will appear here automatically</v-subheader>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.name" label="Sensor Alias"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.building" label="Building"></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="selectedRoom"
                    :items="availableRooms"
                    menu-props="auto"
                    label="Room"
                    hide-details
                    single-line
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.sensorid" disabled label="ID"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.type" disabled label="Type"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.scale" disabled label="Scale"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItem.unit" disabled label="Unit System"></v-text-field>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>

            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
/* import Sensor from '@/components/services/haab/Sensor' */

export default {
  name: 'HaabSensors',
  components: {
    // Sensor
  },
  data () {
    return {
      room: { label: 'Room:' },
      showSensors: false,
      sensor: 'Select a sensor',
      selectedRoom: '',
      dialog: false,
      deleteDialog: false,
      expanded: [],
      fields: [
        {
          value: 'sensorid',
          text: 'Sensor ID',
          sortable: false
        },
        {
          value: 'name',
          text: 'Alias',
          sortable: false
        },
        {
          value: 'type',
          text: 'Type',
          sortable: true
        },
        {
          value: 'room.label',
          text: 'Room',
          sortable: true
        },
        {
          value: 'lastUpdate',
          text: 'Last Sampled',
          sortable: true
        },
        {
          value: 'action',
          text: 'Action',
          sortable: true,
          thStyle: {width: '200px'}
        },
        { text: '', value: 'data-table-expand' }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        sensorid: '',
        type: '',
        scale: '',
        building: '',
        unit: '',
        room: {}
      },
      defaultItem: {
        name: '',
        sensorid: '',
        type: '',
        scale: '',
        building: '',
        unit: '',
        room: {}
      }
    }
  },
  props: {
    mode: String
  },
  methods: {
    ...mapActions(['fetchSensors']),
    ...mapActions(['fetchRooms']),
    ...mapActions(['addSensor']),
    ...mapActions(['deleteSensor']),
    ...mapActions(['updateSensor']),
    editSensor (sensor) {
      this.editedIndex = this.sensors.indexOf(sensor)
      this.editedItem = Object.assign({}, sensor)
      if (this.editedItem.room) {
        this.selectedRoom = this.editedItem.room.label
      }      
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        var _room = {}
        this.rooms.forEach(element => {
          if (element.label === this.selectedRoom) {
            _room = Object.assign({}, element)
          }
        })
        this.editedItem.room = Object.assign({}, _room)
        this.updateSensor(this.editedItem)
      }
      this.fetchSensors()
      this.close()
    },
    deleteConfirm (sensor) {
      this.editedItem = Object.assign({}, sensor)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteSensor(this.editedItem)
      this.close()
    }
  },
  mounted () {
    this.fetchSensors()
    this.fetchRooms()
  },
  computed: {
    ...mapGetters({
      sensors: 'allSensors',
      rooms: 'allRooms'
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    availableRooms () {
      var _rooms = []
      this.rooms.forEach(element => {
        _rooms.push(element.label)
      })
      return _rooms
    },
    timeFixedSensors () {
      var _sensors = []
      this.sensors.forEach(element => {
        element.timeStamp = new Date(element.lastUpdate).toLocaleString()
        element.lastUpdate = moment(element.timeStamp, 'MM/DD/YYYY, h:mm:ss a').fromNow()
        _sensors.push(element)
      })
      return _sensors
    }
  }
}
</script>

<style scoped>


</style>
