<template>
  <v-row class="elevation-2 ma-3 pb-5">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">{{ room.label }}</v-list-item-title>
        <v-list-item-subtitle>{{ roomDescription }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <ControlBox
      v-for="device in local_controls"
      v-bind:key="device.id"
      v-bind:control="device"
      v-bind:mode="mode"
    ></ControlBox>
    <SensorBox
      v-for="sensor in local_sensors"
      v-bind:key="sensor.index"
      v-bind:sensor="sensor"
      v-bind:mode="mode"
    ></SensorBox>
  </v-row>
</template>

<script>
import ControlBox from '@/components/automation/ControlBox'
import SensorBox from '@/components/automation/SensorBox'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RoomBox',
  data () {
    return {
      devices: [ ],
      errors: [ ],
      showDismissibleAlert: false
    }
  },
  methods: {
    ...mapActions(['fetchSensors']),
    ...mapActions(['fetchControls'])
  },
  props: {
    room: Object,
    mode: String
  },
  components: {
    ControlBox,
    SensorBox
  },
  filters: {
  },
  computed: {
    local_sensors () {
      return this.sensors.filter(item => {
        if (item.room !== null) {
          return item.room.label.indexOf(this.room.label) > -1
        } else {
          return []
        }
      })
    },
    local_controls () {
      return this.controls.filter(item => {
        if (item.room !== null) {
          return item.room.label.indexOf(this.room.label) > -1
        } else {
          return []
        }
      })
    },
    ...mapGetters({
      sensors: 'allSensors',
      controls: 'allControls'
    }),
    isToShow () {
      if ((this.local_sensors.length > 0) || (this.local_controls.length > 0)) {
        return true
      } else {
        return false
      }
    },
    roomDescription () {
      return this.editedIndex !== null ? this.room.info : 'Room'
    },
  },
  created () {
    this.fetchSensors()
    this.fetchControls()
  }
}
</script>

<style scoped>



</style>
