import axios from 'axios'
import config from '../../../config/index.js'
// import moment from 'moment'
// var temp = moment(this.last.create_date).format('DD/MM/YYYY, h:mm:ss a');

const state = {
  events: []
}

const getters = {
  allEvents: (state) => state.events
}

const actions = {
  async fetchEvents ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/events', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setEvents', response.data.events)
  },
  async addEvent ({ commit }, event) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/events/add`, {
      timestamp: event.timestamp,
      text: event.text
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushEvent', response.data.event)
  }
}

const mutations = {
  setEvents: (state, events) => (state.events = events),
  pushEvent: (state, event) => (state.events.push(event))
}

export default {
  state,
  getters,
  actions,
  mutations
}
