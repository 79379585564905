<template>
  <v-card>
    <v-tabs
      background-color="grey darken-3"
      centered
      grow
      dark
    >
      <v-tabs-slider color="yellow darken-1"></v-tabs-slider>
      <v-tab>
        Users
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
              <Users/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab>
        Events
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
              <Events/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab>
        Configure
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols=12>
              <Config/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import Events from '@/components/admin/Events'
import Users from '@/components/admin/Users'
import Config from '@/components/admin/Config'

export default {
  components: {
    Events,
    Users,
    Config
  },
  props: {
    mode: String
  }
}
</script>

<style>

</style>
