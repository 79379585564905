<template>
  <div>
    <v-data-table
      :headers="fields"
      :items="itemsUnion"
      item-key="name"
      multi-sort
      calculate-widths
      class="elevation-2 ma-5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          @click="deleteConfirm(item)"
        >
          mdi-delete-empty
        </v-icon>
      </template>
      <template v-slot:[`item.done`]="{ item }">
        <v-checkbox
          v-model="item.done"
          @change="update(item)"
        ></v-checkbox>
      </template>
      <template v-slot:[`item.shared`]="{ item }">
        <v-switch
          :disabled="item.locked"
          v-model="item.shared"
          @change="update(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.description"
          @cancel="cancel"
          @open="open"
          @close="close"
        > {{ item.description }}
          <template v-slot:input>
            <v-text-field
              @change="update(item)"
              v-model="item.description"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:top>
        <v-toolbar dark flat color="blue-grey darken-2">
          <v-toolbar-title>My Shopping List</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <template v-slot:extension>
            <v-btn
              fab
              color="blue-grey lighten-1"
              bottom
              right
              absolute
              @click="dialog = !dialog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-col>
                  <v-text-field v-model="editedItem.description" label="Shopping Item"></v-text-field>
                </v-col>
                <v-col>
                  <v-switch v-model="editedItem.shared" class="ma-4" label="Shared?"></v-switch>
                </v-col>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
            <v-card class="mx-auto">
              <v-card-title>
                <span class="headline">Really delete?</span>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
                <v-btn :disabled="editedItem.locked" color="red darken-1" @click="deleteItem">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      deleteDialog: false,
      dialog: false,
      snack: false,
      snackColor: '',
      snackText: '',
      fields: [
        {
          value: 'done',
          text: 'Done?',
          sortable: true,
          width: '10%'
        },
        {
          value: 'shared',
          text: 'Shared?',
          sortable: true,
          width: '10%'
        },
        {
          value: 'description',
          text: 'Shopping Items',
          sortable: true
        },
        {
          value: 'action',
          text: '',
          sortable: false,
          width: '10%'
        }
      ],
      editedIndex: -1,
      editedItem: {
        done: false,
        shared: false,
        description: ''
      },
      defaultItem: {
        done: false,
        shared: false,
        description: ''
      }
    }
  },
  props: {
    mode: String
  },
  methods: {
    ...mapActions(['fetchShoppingItems']),
    ...mapActions(['fetchShared']),
    ...mapActions(['addShoppingItem']),
    ...mapActions(['deleteShoppingItem']),
    ...mapActions(['updateShoppingItem']),
    editItem (task) {
      this.editedIndex = this.shoppingItems.indexOf(task)
      this.editedItem = Object.assign({}, task)     
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.deleteDialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
      if (this.editedIndex > -1) {
        this.updateShoppingItem(this.editedItem)
      } else {
        this.addShoppingItem(this.editedItem)
      }
      this.close()
    },
    update (item) {
      this.editedIndex = this.shoppingItems.indexOf(item)
      this.editedItem = Object.assign({}, item) 
      this.updateShoppingItem(this.editedItem)
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    deleteConfirm (room) {
      this.editedItem = Object.assign({}, room)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteShoppingItem(this.editedItem)
      this.close()
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    }
  },
  computed: {
    ...mapGetters({
      shoppingItems: 'allShoppingItems',
      sharedItems: 'allShared'
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Shopping Item' : 'Edit Shopping Item'
    },
    itemsUnion () {
      return [...new Set([...this.shoppingItems, ...this.sharedItems])]
    }
  },
  mounted () {
    this.fetchShoppingItems()
    this.fetchShared()
  }
}
</script>

<style scoped>

</style>
