<template>
  <v-card-text>
    <v-row align="center">

      <v-col class="display-3" cols="6">
        {{temperature}}&deg;C

      </v-col>
      <v-col cols="6">
        <v-img
          :src="url"
          alt="Icon"
          width="92"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="headline" cols="12">
        &uarr;{{max}}°C
        &darr;{{min}}°C
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'Figure',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  props: {
    weather: Object
  },
  mounted () {
  },
  computed: {
    url () {
      const pre = 'http://openweathermap.org/img/wn/'
      const pos = '@2x.png'
      var icon = pre + this.weather.weather[0].icon + pos
      return icon
    },
    temperature () {
      return Math.round(this.weather.main.temp - 273.15)
    },
    max () {
      return Math.round(this.weather.main.temp_max - 273.15)
    },
    min () {
      return Math.round(this.weather.main.temp_min - 273.15)
    }
  }
}
</script>

<style scoped>

.blue {
  color: rgb(4, 203, 253);
}

.red {
  color: rgb(233, 38, 135);
}

</style>
