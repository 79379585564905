import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  notes: []
}

const getters = {
  allNotes: (state) => state.notes
}

const actions = {
  async fetchNotes ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/notes', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setNotes', response.data.notes)
    commit('sortNotes')
  },
  async addNote ({ commit }, note) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/notes/add`, {
      label: note.label,
      text: note.text,
      category: note.category
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushNote', response.data.note)
    return(["Success", response.data.note])
  },
  async deleteNote ({ commit }, note) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/notes/delete`, {
      noteId: note._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remNote', note)
    return(["Success"])
  },
  async updateNote ({ commit }, note) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/notes/update`, {
      note: {
        _id: note._id,
        label: note.label,
        text: note.text,
        category: note.category
      }
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remNote', note)
    commit('pushNote', response.data.note)
    commit('sortNotes')
    return(["Success", response.data.note])
  }
}

const mutations = {
  setNotes: (state, notes) => (state.notes = notes),
  pushNote: (state, note) => (state.notes.unshift(note)),
  remNote: (state, delnote) => (state.notes = state.notes.filter(note => note._id !== delnote._id)),
  sortNotes: (state) => {
    state.notes = state.notes.sort(function (a, b) {
      return new Date(b.updateDate) - new Date(a.updateDate)
      // return moment(a.updateDate).unix() > moment(b.updateDate).unix()
      // return a.updateDate.getTime() - b.updateDate.getTime()
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
