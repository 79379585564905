<template>
  <v-container>
    <v-col ma-5 class="pt-2 pl-5 pr-5">
      <Categories v-on:categoryFilter="filterCategory($event)" v-on:newNote="newNote"/>
      <v-col>
        <v-row>
        <v-card
            class="ma-2 d-flex flex-column"
            max-width="600"
            min-width="400"
            max-height="400"
            min-height="100"
            outlined
            elevation="3"
            v-for="note in filteredNotes"
            :key="note._id"
          > 
          <v-system-bar :color="note.category.color" window>
            <span>{{ note.label }}</span>
            <v-spacer></v-spacer>
            <v-icon
              @click="deleteConfirm(note)"
            >
              mdi-delete-empty
            </v-icon>
          </v-system-bar>
          <v-system-bar @click="openNote(note)" color="gray">
           {{note.category.label}}
           <v-spacer></v-spacer>
           <v-icon class="pr-1">mdi-tag</v-icon>
          </v-system-bar>
            <div 
              @click="openNote(note)"
            >
            <vue-markdown
              class="ma-2 caption"
              :watches="['source']"
              placeholder="Empty note..."
              :source="note.text | digest"
            >
            </vue-markdown>
            </div>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-actions>
              <div class="caption font-weight-thin">Updated: {{ note.updateDate | lastUpdated}}</div>
            </v-card-actions>
        </v-card>
        </v-row>
      </v-col>
    </v-col>

    <v-dialog v-model="noteScreen" max-width="1300px" min-height="800x" fullscreen>
      <v-card>
        <v-card-title
          class="headline indigo darken-2"
          primary-title
          >
          
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                color="purple"
                small
                v-on="on"
              >
              <v-icon >mdi-tag</v-icon>
                {{currentNote.category.label | label}}
              </v-btn>

            </template>
            
            <v-list >
              <v-subheader>CATEGORY</v-subheader>
              <v-list-item
                v-for="category in categories"
                :key="category._id"
                @click="selectCategory(category)"
              >
                <v-list-item-title>{{ category.label}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-text-field dark label="Note title" v-model="currentNote.label"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-4" @click="close">Cancel</v-btn>
          <v-btn small color="blue darken-1" @click="save">Save</v-btn>
        </v-card-title>
        <v-container>
          <v-icon @click="edit = !edit">mdi-pencil</v-icon>
          <v-row>
            <v-col v-if="edit">
              <v-textarea
                clearable
                auto-grow
                class="caption"
                label="Note Source"
                v-model="currentNote.text"
                outlined
                hint="Feel free to use markdown"
              ></v-textarea>
            </v-col>
            <v-col class="caption text-left mt-3 mb-11">
              <vue-markdown
                :watches="['source']"
                placeholder="Empty note..."
                :source="currentNote.text"
              >
              </vue-markdown>
            </v-col>
            </v-row> 
        </v-container>
      </v-card>

    </v-dialog >
    <v-dialog v-model="deleteDialog" max-width="600px" max-heigth="300px">
      <v-card class="mx-auto">
        <v-card-title>
          <span class="headline">Really delete?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-4" @click="close">Cancel</v-btn>
          <v-btn color="red darken-1" @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueMarkdown from 'vue-markdown' // production
import moment from 'moment'
import Categories from '@/components/services/notes/Categories'

export default {
  data () {
    return {
      snack: false,
      edit: false,
      snackColor: '',
      snackText: '',
      deleteDialog: false,
      noteSelected: false,
      noteScreen: false,
      editing: false,
      selectedCategories: [],
      note: "",
      editedIndex: -1,
      currentNote: {
        _id: '',
        label: '',
        text: '',
        category: {}
      },
      defaultItem: {
        _id: '',
        label: '',
        text: '',
        category: {}
      },
    }
  },
  components: {
    Categories,
    VueMarkdown
  },
  props: {
    mode: String
  },
  methods: {
    ...mapActions(['fetchNotes']),
    ...mapActions(['addNote']),
    ...mapActions(['deleteNote']),
    ...mapActions(['updateNote']),
    deleteConfirm (note) {
      this.editedIndex = this.notes.indexOf(note)
      this.currentNote = Object.assign({}, note)
      this.deleteDialog = true
    },
    deleteItem () {
      this.deleteNote(this.currentNote)
      .then((value) => {
        if (value[0] === "Success") {
          this.snack = true
          this.snackColor = 'success'
          this.snackText = 'Note Deleted'
        }
      })
      this.close()
    },
    close () {
      this.noteScreen = false
      this.deleteDialog = false
      setTimeout(() => {
        this.currentNote = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      if (this.editedIndex > -1) {
        this.updateNote(this.currentNote)
        .then((value) => {
          if (value[0] === "Success") {
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Note Updated'
          }
        })
      } else {
        if (this.currentNote.label !== '' && Object.keys(this.currentNote.category).length !== 0) {
          this.addNote(this.currentNote)
          .then((value) => {
            if (value[0] === "Success") {
              this.snack = true
              this.snackColor = 'success'
              this.snackText = 'Note Added'
            }
          })
        } else {
          this.snack = true
          this.snackColor = 'alert'
          this.snackText = 'Note title and Category cannot be empty'
          return
        }
      }
      this.close()
    },
    openNote (note) {
      this.edit = false
      this.noteScreen = true
      this.editedIndex = this.notes.indexOf(note)
      this.currentNote = Object.assign({}, note)
    },
    newNote () {
      this.noteScreen = true
      this.edit = true
    },
    filterCategory(category) {
      this.selectedCategories = category
    },
    selectCategory(category) {
      this.currentNote.category = Object.assign({}, category)
    }
  },
  computed: {
    ...mapGetters({
      notes: 'allNotes',
      categories: 'allCategories'
    }),
    filteredNotes(){
      return this.notes.filter(note => {
        if (this.selectedCategories.length === 0) {
          return note
        } else {
          if (this.selectedCategories.includes(note.category.label)) {
            return note
          }
        }
      })
    }
  },
  mounted () {
    this.fetchNotes()
  },
  watch: {
  },
  filters: {
    digest (note) {
      return (note.length >= 300) ? note.substring(0, 300) + ' ...' : note
      // return note.substring(0, 250) + ' ...'
    },
    label (category) {
      return (typeof category === 'undefined') ? 'Not categorized' : category
    },
    lastUpdated (date) {
      var temp = moment(date).format('DD/MM/YYYY, h:mm:ss a');
      return moment(temp, 'DD/MM/YYYY, h:mm:ss a').fromNow()
    }
  }
}
</script>

<style scoped>

</style>
