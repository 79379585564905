import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  shoppingItems: [],
  sharedItems: []
}

const getters = {
  allShoppingItems: (state) => state.shoppingItems,
  allShared: (state) => state.sharedItems
}

const actions = {
  async fetchShoppingItems ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/lists/shoppingItems', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setShoppingItems', response.data.shoppingItems)
  },
  async fetchShared ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/lists/shoppingShared', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setShared', response.data.shoppingItems)
  },
  async addShoppingItem ({ commit }, shoppingItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/lists/shoppingItem/add`, {
      description: shoppingItem.description,
      shared: shoppingItem.shared
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushShoppingItem', response.data.shoppingItem)
  },
  async deleteShoppingItem ({ commit }, shoppingItem) {
    const token = localStorage.getItem('jwtToken')
    await axios.post(config.api.url + ':' + config.api.port + `/lists/shoppingItem/delete`, {
      shoppingItemId: shoppingItem._id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remShoppingItem', shoppingItem)
  },
  async updateShoppingItem ({ commit }, shoppingItem) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/lists/shoppingItem/update`, {
      shoppingItem: shoppingItem
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (state.sharedItems.includes(shoppingItem)) {
      commit('remSharedItem', shoppingItem)
      commit('pushSharedItem', response.data.shoppingItem)
    } else if (state.shoppingItems.includes(shoppingItem)) {
      commit('remShoppingItem', shoppingItem)
      commit('pushShoppingItem', response.data.shoppingItem)
    }
  }
}

const mutations = {
  setShoppingItems: (state, shoppingItems) => (state.shoppingItems = shoppingItems),
  setShared: (state, shoppingItems) => (state.sharedItems = shoppingItems),
  pushShoppingItem: (state, shoppingItem) => (state.shoppingItems.unshift(shoppingItem)),
  remShoppingItem: (state, delshoppingItem) => (state.shoppingItems = state.shoppingItems.filter(shoppingItem => shoppingItem._id !== delshoppingItem._id)),
  pushSharedItem: (state, shoppingItem) => (state.sharedItems.unshift(shoppingItem)),
  remSharedItem: (state, delshoppingItem) => (state.sharedItems = state.sharedItems.filter(shoppingItem => shoppingItem._id !== delshoppingItem._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
