<template>
    <div>  
    <v-data-table
      :headers="fields"
      :items="events"
      :items-per-page="15"
      class="elevation-1"
      dense
      calculate-widths
    >
    <template v-slot:[`item.create_date`]="{ item }"> 
      {{ item.create_date |  localtime }}
    </template>
    </v-data-table>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Events',
  data () {
    return {
      fields: [
        {
          value: 'create_date',
          text: 'Local Time',
          sortable: true,
          width: '20%'
        },
        {
          value: 'timestamp',
          text: 'DB Timestamp',
          sortable: true,
          width: '10%'
        },
        {
          value: 'text',
          text: 'Text',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchEvents'])
  },
  props: {
    mode: String
  },
  mounted () {
  },
  created () {
    this.fetchEvents()
  },
  computed: {
    ...mapGetters({
      events: 'allEvents'
    })
  },
  filters: {
    localtime: function (utc_time) {
      const utc_date = new Date(utc_time);
      var localstring = utc_date.toLocaleString()
      return localstring.replace(',','');
    }
  }
}
</script>

<style scoped>

</style>
