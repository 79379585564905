import axios from 'axios'
import config from '../../../config/index.js'

const state = {
  weather: {},
  cities: []
}

const getters = {
  currentWeather: (state) => state.weather,
  allCities: (state) => state.cities
}

const actions = {
  async fetchWeather ({ commit }, city) {
    if (city == null) {
      city = 'Vasteras'
    }
    const response = await axios.get(config.weather.url + '/', {
      headers: {
      },
      params: {
        q: city,
        appid: config.weather.key
      }
    })
    commit('setWeather', response.data)
  }/* ,
  async addCity ({ commit }) {

  },
  async fetchCities ({ commit }) {

  },
  async removeCity ({ commit }) {

  } */
}

const mutations = {
  setWeather: (state, weather) =>  {
    state.weather = weather
    /*if (state.weather.length === 0){
      state.weather.push(weather)
    } else {
      state.weather.forEach(element => {
        if (element.id === weather.id) {
          element = weather
        } else {
          state.weather.push(weather)
        }
      });*/
    
  },
  // setCity: (state, cities) => (state.cities = cities),
  // pushCity: (state, city) => (state.cities.push(city)),
  // remCity: (state, delcity) => (state.cities = state.cities.filter(city => city._id !== delcity._id))
}

export default {
  state,
  getters,
  actions,
  mutations
}
