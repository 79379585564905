import axios from 'axios'
import config from '../../../config/index.js'

const getDefaultState = () => {
  return {
    users: [],
    user: {}
  }
}

const state = getDefaultState()

const getters = {
  allUsers: (state) => state.users,
  myUser: (state) => state.user
}

const actions = {
  async fetchUsers ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/users/profile/all', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setUsers', response.data.activerusers)
  },
  async fetchMyUser ({ commit }) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.get(config.api.url + ':' + config.api.port + '/users/profile', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setMyUser', response.data)
  },
  async reset () {
    getDefaultState()
  },
  async deleteUser ({ commit }, user) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/users/delete`, {
      user: user
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('remUser', response.data.user)
  },
  async addUser ( { commit },user ) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/users/register`, {
      user: user
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('pushUser', response.data.user)
  },
  async updateUser ({ commit }, user) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(config.api.url + ':' + config.api.port + `/users/updateProfile`, {
      user: user
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('updateUser', response.data.user)
  }
}

const mutations = {
  remUser: (state, delUser) => (state.users = state.users.filter(user => user.email !== delUser.email)),
  pushUser: (state, user) => (state.users.push(user)),
  setUsers: (state, users) => (state.users = users),
  setMyUser: (state, user) => (state.user = user),
  updateUser: (state, updateUser) => {
    let index = state.users.findIndex(user => user._id == updateUser._id)
    state.users[index] = updateUser
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
