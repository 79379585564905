import axios from 'axios'
import configuration from '../../../config/index.js'

const state = {
  config: {}
}

const getters = {
  config: (state) => state.config
}

const actions = {
  async fetchConfig ({ commit }) {
    var return_message = []
    const token = localStorage.getItem('jwtToken')
    await axios.get(configuration.api.url + ':' + configuration.api.port + '/config', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      commit('setConfig', response.data.config)
    })
    .catch(function (err) {
      if (err.request.status == 401) {
        
        return_message = ['LOGOUT']
      }
    })
    return(return_message)
  },
  async updateConfig ({ commit }, config) {
    const token = localStorage.getItem('jwtToken')
    const response = await axios.post(configuration.api.url + ':' + configuration.api.port + `/config/update`, {
        config
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    commit('setConfig', response.data.config)
  }
}

const mutations = {
  setConfig: (state, config) => {
    state.config = config[0]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}